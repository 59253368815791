<template>
  oject
  <div class="trainee-list-modal-overlay" @click="closeModalOutside">
    <div class="trainee-list-modal" @click.stop>
      <div class="modal-content">
        <h2>Edit Task ID: {{ newTask.task_id }}</h2>
        <div class="add">
          <form @submit.prevent="updateTask">
            <div class="form-group">
              <label for="task_no">Task No:</label>
              <input type="text" class="form-control rounded" id="task_no" name="task_no" placeholder="Enter Task Number"
                v-model="newTask.task_no" />
            </div>

            <div class="form-group">
              <label for="description">Activity:</label>
              <input type="text" class="form-control rounded" id="description" name="description"
                placeholder="Enter Activity" v-model="newTask.activity" />
            </div>

            <div class="form-group">
              <label for="scope">AMTOSS:</label>
              <select class="form-control rounded" id="scope" name="scope" v-model="newTask.AMTOSS">
                <option value=""></option>
                <option value="LOC">LOC</option>
                <option value="SGH">SGH</option>
                <option value="TS">TS</option>
                <option value="FOT">FOT</option>
                <option value="MEL">MEL</option>
                <option value="GVC">GVC</option>
                <option value="DDC">DDC</option>
                <option value="AHA">AHA</option>
                <option value="SPC">SPC</option>
                <option value="ADJ">ADJ</option>
                <option value="INS">INS</option>
                <option value="STD">STD</option>
                <option value="FUC">FUC</option>
                <option value="REM">REM</option>
                <option value="LUB">LUB</option>
                <option value="SMR">SMR</option>
                <option value="TVC">TVC</option>
              </select>
            </div>

            <div class="form-group">
              <label for="location">Location:</label>
              <select class="form-control rounded" id="location" name="location" v-model="newTask.location">
                <option value=""></option>
                <option value="AC">AC</option>
                <option value="TPR">TPR</option>
                <option value="TPR">AC / TPR</option>
              </select>
            </div>

            <div class="form-group">
              <label for="area">Area:</label>
              <select class="form-control rounded" id="area" name="area" v-model="newTask.area">
                <option value=""></option>
                <option value="CRG">CRG</option>
                <option value="CAB">CAB</option>
                <option value="L/G">L/G</option>
                <option value="F/C">F/C</option>
                <option value="ENG">ENG</option>
                <option value="W/L">W/L</option>
                <option value="W/R">W/R</option>
                <option value="FUS">FUS</option>
                <option value="HYD">HYD</option>
                <option value="E&A">E&A</option>
              </select>
            </div>

            <div class="form-group">
              <label for="category">Category:</label>
              <input type="text" class="form-control rounded" id="category" name="category"
                placeholder="Enter Category" v-model="newTask.category" />
            </div>

            <div class="form-group">
              <label for="chapt_no">Chapter No:</label>
              <input type="text" class="form-control rounded" id="chapt_no" name="chapt_no"
                placeholder="Enter Chapter #" v-model="newTask.chapt_no" />
            </div>

            <div class="form-group">
              <label for="module">Module:</label>
              <input type="text" class="form-control rounded" id="chapt_n" name="module"
                placeholder="Enter Chapter #" v-model="newTask.module" />
            </div>

            <div class="form-group">
              <label for="manob">Manob:</label>
              <select class="form-control rounded" id="manob" name="manob" v-model="newTask.manob">
                <option value=""></option>
                <option value="M">M</option>
                <option value="O">O</option>
              </select>
            </div>

            <div class="form-group">
              <label for="level">Level:</label>
              <select class="form-control rounded" id="level" name="level" v-model="newTask.level">
                <option value=""></option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div>

            <div v-if="showAlert" class="alert alert-danger error-message" role="alert">
              <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
            </div>
            <div>
              <button class="btn btn-primary rounded" type="submit">Update Task</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

import axios from 'axios';

export default {
  data() {
    return {
      instructors: [],
      products: [],
      tasks: [],
      newTask: {
        task_no: '',
        manob: '',
        AMTOSS: '',
        activity: '',
        chapt_no: '',
        level: '',
        location: '',
        module: '',
        area: '',
      },
      showAlert: false,
      currentTime: '',
      errorMessages: {},
      errorMessage: '',
      productSelected: false,
      taskSelected: false,
      showTraineeList: false,
    };
  },
  methods: {

    async updateTask() {
      try {
        const task_id = localStorage.getItem('task_id');
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        console.log('task_id')
        console.log(this.newTask);

        const response = await axios.put(
          `/api/tasks/${task_id}`,
          {
            task_no: this.newTask.task_no,
            manob: this.newTask.manob,
            AMTOSS: this.newTask.AMTOSS,
            activity: this.newTask.activity,
            chapt_no: this.newTask.chapt_no,
            level: this.newTask.level,
            location: this.newTask.location,
            module: this.newTask.module,
            area: this.newTask.area,

          },
          { headers }
        );

        if (response.status === 200) {
          window.alert('Task updated Successfully');
          this.tasks.push(response.data);
          this.newTask = {
            task_no: '',
        manob: '',
        AMTOSS: '',
        activity: '',
        chapt_no: '',
        level: '',
        location: '',
        module: '',
        area: '',
          };
          this.errorMessage = '';
          this.showAlert = false;
        }
        await this.fetchTasks();
        window.location.reload();
      } catch (error) {
        console.error('Error adding User:', error.response);


        if (error.response && error.response.data && error.response.data.errors) {
          // Handle validation errors
          const validationErrors = error.response.data.errors;

          // Build an error message from the validation errors
          let errorMessage = 'Validation errors:';
          for (const field in validationErrors) {
            errorMessage += `\n${field}: ${validationErrors[field][0]}`;
          }

          this.errorMessage = errorMessage;
        } else {
          // Handle other errors
          this.errorMessage = 'An error occurred while adding the user.';
        }

        this.showAlert = true;
      }
    },
    async fetchTasks() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.display('unauthorized');
          return;
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get('/api/tasks', { headers });
        console.log('task response')
        console.log(response);

        if (response.status === 200) {
          this.tasks = response.data;
          console.log('tasks');
          console.log(this.tasks);
        }
      } catch (error) {
        console.error('Error retrieving tasks:', error);
        // Handle error
      }
    },

    goBack() {
      this.$router.push({ name: 'ViewTasks' });
    },
    closeModalOutside() {
      this.$emit('close'); // Emit an event to indicate the modal should be closed
    },
    getTaskData(task_id) {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        axios.get(`/api/tasks/${task_id}`, { headers })
          .then(res => {
            this.newTask = res.data;
            console.log(res.data);
          });
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
      }
    },
  },
  async mounted() {
    this.getTaskData(localStorage.getItem('task_id'));
    await this.fetchTasks();

  },
}
</script>
  
<style scoped>
.trainee-list-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure the modal is on top of other content */
}

h2 {
  margin-top: 30px;
  font-size: 30px;
  margin-bottom: 30px;
}

/* Style your modal content */
.trainee-list-modal {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 800px;
  /* Limit the maximum width of the modal */
  padding: 20px;
  position: relative;
  max-height: 95%;
}


.modal-content {
  max-height: 90vh;
  /* Adjust the maximum height as needed */
  overflow-y: auto;
  /* Enable scrolling within the modal content */
}


.add button {
  width: 200px;
  height: 40px;
  margin-top: 50px;
  margin-bottom: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
}

.add{
  margin-top: 0;
  height:90%;
}
button.btn:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

input.form-control,
select.form-control {
  width: 65%;
  margin-left: 200px;
  display: inline-block;
  margin-bottom: 25px;
  align-items: baseline;
}

.form-group {
  margin-bottom: 15px;
}

label {
  margin-right: 5px;
  position: absolute;
  margin-top: 8px;
}

.form-control {
  border-radius: 5px;
}

.form-control#start_date .form-control#end_date {
  border-radius: 5px;
  width: 25%;
  margin-left: 100px;
  margin-right: 45%;
}

h1 {
  margin-bottom: 20px;
}
</style>
  