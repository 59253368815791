<template>
    <div>
        <page-header></page-header>

        <!-- Heading and Filename -->
        <div>
            <table class="header-section">
                <td>
                    IMPORT TASKS
                </td>
                <td style="color: grey;">
                    {{ fileName }}
                </td>
            </table>
        </div>

        <button class="back-button" @click="goBack">Back</button>

        <!-- Import Information -->
        <div :class="{ 'error-info-box': skippedTasks.length === importedTasks.length }" class="info-box">
            <!-- Display success or failure message based on the condition -->
            <div v-if="skippedTasks.length === importedTasks.length">
                Tasks Import - Unsuccessful.
                <li>Total record(s) : {{ importedTasks ? importedTasks.length : 0 }}</li>
                <li>Valid task(s): {{ validTaskCount }}</li>
                <li style="color:red">Skipped task(s): {{ skippedTasks ? skippedTasks.length : 0 }}</li>            
                <p></p>
                Task(s) added to Database: {{ validTaskCount }}
            </div>
            <div v-else>
                Tasks Import - Successful!
                <li>Total record(s) : {{ importedTasks ? importedTasks.length : 0 }}</li>
                <li>Valid task(s): {{ validTaskCount }}</li>
                <li style="color:red">Skipped task(s): {{ skippedTasks ? skippedTasks.length : 0 }}</li>            
                <p></p>
                Task(s) added to Database: {{ validTaskCount }}
            </div>
        </div>

        <div class="skippedTasksDisplay">
            <div v-if="skippedTasks.length">
                <div v-for="item in skippedTasks" :key="item.task ? item.task.task_no : Math.random()">
                    <!-- Display reason for skipped task -->
                    <ul v-if="item && item.task">
                        <li style="font-weight:bold; white-space: pre-line;">
                            Skipped Task - {{ item.task.task_no }}: {{ item.message }}
                        </li>
                    </ul>
                    <i v-if="item && item.task" style="color:grey; margin-left:20px;">Provided fields</i>
                    <div v-if="item && item.task" class="task-list">
                        <ol>
                            <li v-if="getTaskByNo(parseInt(taskNo))"><strong>Task no: &nbsp;</strong> {{ getTaskByNo(parseInt(taskNo)).task_no }}</li> 
                            <li v-if="getTaskByNo(parseInt(taskNo))"><strong>Ratings: &nbsp;</strong> {{ getTaskByNo(parseInt(taskNo)).ratings }}</li>                         
                            <li v-if="getTaskByNo(parseInt(taskNo))"><strong>Module: &nbsp;</strong> {{ getTaskByNo(parseInt(taskNo)).module }}</li>
                            <li v-if="getTaskByNo(parseInt(taskNo))"><strong>Chapter No: &nbsp;</strong> {{  getTaskByNo(parseInt(taskNo)).chapt_no  }}</li>
                            <li v-if="getTaskByNo(parseInt(taskNo))"><strong>Location: &nbsp;</strong> {{ getTaskByNo(parseInt(taskNo)).location }}</li>
                            <li v-if="getTaskByNo(parseInt(taskNo))"><strong>AMTOSS: &nbsp;</strong> {{ getTaskByNo(parseInt(taskNo)).AMTOSS }}</li>
                            <li v-if="getTaskByNo(parseInt(taskNo))"><strong>Activity: &nbsp;</strong> {{ getTaskByNo(parseInt(taskNo)).activity }}</li>
                            <li v-if="getTaskByNo(parseInt(taskNo))"><strong>Category: &nbsp;</strong> {{ getTaskByNo(parseInt(taskNo)).category }}</li>
                            <li v-if="getTaskByNo(parseInt(taskNo))"><strong>Level: &nbsp;</strong> {{ getTaskByNo(parseInt(taskNo)).level }}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pageHeader from '@/components/Examiner/Header.vue';

export default {
    data() {
        return {
            productId: this.$route.params.productId,
            entireFileRows: [],
            previewRows: [],
        };
    },
    components: {
        pageHeader,
    },
    computed: {
        // Use Vuex to get required data for this component
        skippedTasks() {
            console.log(this.$store.state.skippedTasks);
            return this.$store.state.skippedTasks;
        },
        importedTasks() {
            console.log(this.$store.state.importedTasks);
            return this.$store.state.importedTasks;
        },
        validTaskCount() {
            return this.importedTasks.length - this.skippedTasks.length;
        },

        groupedSkippedTasks() {
            if (Array.isArray(this.skippedTasks)) {
                return this.skippedTasks.reduce((acc, task) => {
                    (acc[task.task_no] = acc[task.task_no] || []).push(task);
                    return acc;
                }, {});
            } else {
                return {};  // or any default value you want
            }
        },
        fileName() {
            return this.$store.state.fileName;
        }
    },
    methods: {
        displayIssues() {
            console.log("Import Issues:", this.issues);
        },
        getRowNumber(issue) {
            const taskNo = issue.task_no ? issue.task_no : '';
            // Find index in the importedTasks
            const index = this.importedTasks.findIndex(task => task.task_no === taskNo);
            return index; // +1 because CSV row numbers start from 1
        },
        getTaskByNo(taskNo) {
    if (!isNaN(taskNo)) {
        const task = this.importedTasks.find((task) => task.task_no !== undefined && task.task_no === taskNo);
        return task;
    } else {
        // If task_no is undefined, find the task by row number
        const rowIndex = this.getRowNumber(taskNo); // Use task_no parameter here
        //console.log(this.importedTasks[rowIndex]);
        return rowIndex !== -1 ? this.importedTasks[rowIndex] : {};
    }
},
        goBack() {
      this.$router.push({ name: 'productPage' });
    },
    },
    mounted() {
        console.log("Skipped Tasks:", this.$store.state.skippedTasks);
    }
}
</script>

<style scoped>
.header-section {
    text-align: center;
    height: 90%;
}

.header-section td{
    font-size: 30px;
    padding-left: 30px;
}

.info-box {
    border: 1px solid #ccc;
    padding: 20px;
    margin: 0px 20px;
    color: #137e2a;
    font-weight: bold;
    background-color: #bfeec7;
    width: 60%;
    height: 180px;
    text-align: left;
}

.error-info-box {
    border: 1px solid #a31616;
    padding: 20px;
    margin: 0px 20px;
    color: #550000;
    font-weight: bold;
    background-color: rgba(255, 109, 109, 0.555);
    width: 60%;
    height: 180px;
    text-align: left;
}


.skippedTasksDisplay {
    text-align: left;
    margin-top: 10px;
    margin-left: 1%;
    background-color: rgb(250, 248, 248);
    width: 98%;
}

.task-list {
    background-color: #f0e7e7;
}

.back-button {
  position: absolute;
  /* Adjust the top position as needed */
  right: 60px;
  /* Adjust the right position as needed */
  width: 100px;
  border-radius: 5px;
  background-color: #c1e0bf;
  font-size: 14px;
  border: 2px solid black;
  padding: 3px;
}
.back-button:hover {
  background-color: #abc7a9;
  cursor: pointer;
}
</style>