<template>
    <!DOCTYPE html>
    <html lang="en">

    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
    </head>

    <body>
        <page-header></page-header>
        <div>
            <div class="import-tasks-modal">
                <div class="tasks-container-fluid no-padding">
                    <div class="row">
                        <div class="col-md-12 left-align-text">
                            <h2>Import tasks from .CSV file</h2>
                            <p style="font-size:16px;">The file has to be a spreadsheet file (.csv) in the following format:
                            </p>
                            <p style="font-weight:bold;">|Task No|Ratings|Module|Chapter No|Location|AMTOSS|Category|Activity|Level|
                            </p>
                            <p style="font-size:16px; color: rgb(196, 0, 0);"><b>All fields except Ratings are mandatory.</b></p>
                            <div class="instructions-area">
                                <table class="inst-table">
                                    <thead>
                                        <tr>
                                            <th>Column</th>
                                            <th>Name</th>
                                            <th>Instructions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>A</td>
                                            <td>Task No</td>
                                            <td>A unique number to identify the tasks</td>
                                        </tr>
                                        <tr>
                                            <td>B</td>
                                            <td>Ratings</td>
                                            <td>Ratings 1-7 determining the category/categories of a task. (only applies for category B2 Tasks)</td>
                                        </tr>
                                        <tr>
                                            <td>C</td>
                                            <td>Module</td>
                                            <td>The module number relating to the task</td>
                                        </tr>
                                        <tr>
                                            <td>D</td>
                                            <td>Chapter No</td>
                                            <td>A valid ATA chapter Number</td>
                                        </tr>
                                        <tr>
                                            <td>E</td>
                                            <td>Location</td>
                                            <td>The location of the task.</td>
                                        </tr>
                                        <tr>
                                            <td>F</td>
                                            <td>AMTOSS</td>
                                            <td>The type of task. E.g LOC</td>
                                        </tr>
                                        <tr>
                                            <td>G</td>
                                            <td>Category</td>
                                            <td>Category of the tasks.</td>
                                        </tr>
                                        <tr>
                                            <td>H</td>
                                            <td>Activity</td>
                                            <td>The description of the task</td>
                                        </tr>
                                        <tr>
                                            <td>I</td>
                                            <td>Level</td>
                                            <td>The difficulty of the task</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <span class="clip" role="img" aria-label="Paperclip">&#x1F4CE;</span>
                                <input type="file" @change="importFile" class="file-input" />
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div v-if="previewRows.length && !importSuccessful && !dryRunSuccessful">
                                <h4 style="text-align: left;">CSV Preview (First 5 rows):</h4>
                                <table class="preview-table">
                                    <!-- Uploaded CSV headers (dynamic) -->
                                    <thead>
                                        <tr>
                                            <th class="narrow-column">Task No</th>
                                            <th class="narrow-column">Ratings</th>
                                            <th class="narrow-column">Module</th>
                                            <th class="narrow-column">Chapt No.</th>
                                            <th class="narrow-column">Location</th>
                                            <th class="narrow-column">AMTOSS</th>
                                            <th class="narrow-column">Category</th>
                                            <th class="wide-column">Activity</th>
                                            <th class="narrow-column">Level</th>
                                        </tr>
                                    </thead>
                                    <!-- Uploaded CSV preview -->
                                    <tbody>
                                        <tr v-for="(row, index) in previewRows" :key="row.toString()"
                                            v-show="!(ignoreFirstRow && index === 0)">
                                            <td style="font-size:14px" v-for="cell in row" :key="cell">{{ cell }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="dryRunSuccessful" v-html="successMessage.replace(/\n/g, '<br>')" class="successBox">
                            </div>
                            <div v-if="importSuccessful && !dryRun" v-html="successMessage.replace(/\n/g, '<br>')"
                                class="successBox"></div>
                        </div>

                        <div class="importArea">
                            <label>
                                <input type="checkbox" v-model="ignoreFirstRow" class="ignoreCheckbox">
                                Ignore first row
                            </label>
                            <label>
                                <input type="checkbox" v-model="dryRun" class="dryRuncheckbox">
                                Dry Run
                            </label>
                            <div class="buttonContainer">
                                <button class="importButton" :disabled="processing" @click.stop.prevent="handleImport">
                                    {{ processing ? 'Importing...' : 'IMPORT' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </body>

    </html>
</template>
  
<script>
import axios from 'axios';
import pageHeader from '@/components/Examiner/Header.vue';
import './importTasks.css';
export default {
    name: "importTasksB2",
    data() {
        return {
            productId: this.$route.params.productId,
            previewRows: [],
            entireFileRows: [],
            ignoreFirstRow: false,
            importSuccessful: false,
            dryRunSuccessful: false,
            successMessage: '',
            dryRun: true,
            importedTasks: [],
            existingTasks: [],
            fileName: '',
            sectionIds: [],
            productIds: [],
            skippedTasks: [],
            validTasks: [],
            processing: false,
        };
    },
    components: {
        pageHeader
    },
    watch: {
        selectedFile(newFile, oldFile) {
            if (newFile !== oldFile) {
                this.resetImportState();
            }
        }
    },
    mounted() {
        const productId = localStorage.getItem('product_id');

        axios.get(`/api/tasks/products/${productId}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(response => {
                this.existingTasks = response.data;
            });
    },
    methods: {
        closeModal() {
            this.$emit('close');
            this.$emit('modal-closed');
        },
        importFile(event) {
    const file = event.target.files[0];

    if (file) {
        this.fileName = file.name;
        if (file.type.includes('csv')) {
            const reader = new FileReader();
            reader.onload = () => {
                const rows = reader.result.trim().split('\n');
                this.entireFileRows = rows.map(row => {
                    const columns = row.split('|');
                    // Combine ratings here
                    const combinedRatings = columns.slice(1, 8).map(column => column.replace(/\s/g, '')).join('');
                    return [columns[0], combinedRatings, ...columns.slice(8)];
                });
                this.previewRows = this.entireFileRows.slice(0, 5);
            };
            reader.readAsText(file);
        }
    } else {
        this.fileName = '';
        this.entireFileRows = [];
        this.previewRows = [];
    }
},
processDataRows(dataRows) {
            return dataRows
                .filter(row => Array.isArray(row) && row.length > 0)
                .map((row) => {
                    const task_no = isNaN(parseInt(row[0], 10)) ? row[0] : parseInt(row[0], 10);
                    const ratings = row[1].trim();
                    const module = row[2].trim();
                    const chapt_no = isNaN(parseInt(row[3], 10)) ? row[3] : parseInt(row[3], 10);
                    const location = row[4].trim();
                    const AMTOSS = row[5].trim();
                    const category = row[6].trim();
                    const activity = row[7].trim();
                    const level = isNaN(parseInt(row[8], 10)) ? row[8] : parseInt(row[8], 10);    // Sixth column
                    const productId = parseInt(localStorage.getItem('product_id'), 10);
                    return {
                        task_no,
                        ratings,
                        module,
                        chapt_no,
                        location,
                        AMTOSS,
                        category,
                        activity,
                        level,
                        prod_id: productId,
                    };
                })
        },
        handleImport() {
            //eslint-disable-next-line
            debugger;
            this.dryRunSuccessful = false;
            this.processing = true;

            // Process the rows first
            const processedTasks = this.processDataRows(this.entireFileRows);

            if (this.ignoreFirstRow) {
                processedTasks.shift(); // Remove the first row if the user wants to ignore it
            }
            if (this.dryRun) {
                this.checkTasks(processedTasks);
                this.dryRunImport(processedTasks);
            } else {
                this.checkImportTasks(processedTasks);
                
            }
        },
        dryRunImport(processedTasks) {
            let dryRunSkippedTasks = [];

            const duplicates = this.checkForDuplicates(processedTasks);
            dryRunSkippedTasks = duplicates.map(task => task.task_no);

            console.log("Dry Run Mode: These tasks would have been imported:", processedTasks);

            if (dryRunSkippedTasks.length > 0) {
                console.log("Duplicate tasks that would have been skipped:", dryRunSkippedTasks);
            } else {
                this.successMessage = `Dry Run Mode: ${processedTasks.length} tasks would have been imported. No issues found in the file.`;
                console.log(this.successMessage);

            }

            this.dryRunSuccessful = true;
            this.processing = false;
        },
        async batchImportTasks(validTasks, skippedTasks, processedTasks) {
            try {
                const token = localStorage.getItem('token');
                const headers = { Authorization: `Bearer ${token}` };

                const response = await axios.post('/api/tasks/batch', validTasks, { headers });

                const responseTasks = response.data.tasks;

                // Handle the responseTasks as needed
                console.log('Batch Import Successful:', responseTasks);
                console.log(skippedTasks);
                this.$store.dispatch('setImportedTasks', processedTasks);
                this.$store.dispatch('setFileName', this.fileName);
                this.$store.dispatch('setSkippedTasks', skippedTasks);
                this.processing = false;
                this.$router.push({ name: 'importSuccessfulB2' });
            } catch (error) {
                console.error('Batch Import Error:', error.response ? error.response.data : error.message);
                this.processing = false;

            }
        },
        // async importTasks(processedTasks) {

        //     this.processing = true;
        //     const headers = {
        //         Authorization: `Bearer ${localStorage.getItem('token')}`,
        //     };

        //     const problematicTasks = this.skippedTasks.map(task => task.task_no);
        //     const validTasks = processedTasks.filter(
        //         task => !problematicTasks.includes(task.task_no),
        //     );

        //     // Define promises variable here
        //     const promises = validTasks.map(task =>
        //         axios.post('/api/tasks', { ...task, prod_id: this.productId }, { headers })
        //         .catch(error => {
        //             if (error.response.status === 422) {
        //                 const errorMessages = Object.entries(error.response.data.errors)
        //                     .map(([field, messages]) => `${field}: ${messages.join(' ')}`)
        //                     .join('\n');
        //                 this.skippedTasks.push({ task: task, message: `Validation Error: \n ${errorMessages}` });
        //             } else if (error.response.status === 409) {
        //                 this.skippedTasks.push({ task: task, message: 'Duplicate Task or Conflict' });
        //             } else if (error.response.status === 500) {
        //                 console.log(error);
        //                 this.skippedTasks.push({ task: task, message: 'Server error. Please contact the Admin for more details' });
        //             }
        //         })
        //     );

        //     await Promise.allSettled(promises)
        //     console.log(this.skippedTasks);
        //     this.$store.dispatch('setImportedTasks', processedTasks);
        //     console.log(this.$store.state.processedTasks);
        //     this.$store.dispatch('setFileName', this.fileName);
        //     this.$store.dispatch('setSkippedTasks', this.skippedTasks);
        //     this.processing = false;
        //     this.$router.push({ name: 'importSuccessful' });

        // },

        areTasksEqual(task1, task2) {
            for (let key in task1) {
                if (key !== 'task_id' && key !== 'created_at' && key !== 'updated_at' && task1[key] !== task2[key]) {
                    return false;
                }
            }
            for (let key in task2) {
                if (key !== 'task_id' && key !== 'created_at' && key !== 'updated_at' && task1[key] !== task2[key]) {
                    return false;
                }
            }
            return true;
        },
        checkForDuplicates(importedTasks) {
            const duplicates = [];

            for (let i = 0; i < importedTasks.length; i++) {
                for (let j = 0; j < this.existingTasks.length; j++) {
                    if (this.areTasksEqual(importedTasks[i], this.existingTasks[j])) {
                        duplicates.push(importedTasks[i]);
                        break;  // No need to check further for this importedTask if a duplicate is found
                    }
                }
            }
            return duplicates;
        },
        checkTasks(importedTasks, isDryRun = true) {

            //eslint-disable-next-line
            debugger;
            const issues = [];
            this.skippedTasks = [];
            this.validTasks = [];

            const duplicates = this.checkForDuplicates(importedTasks);

            importedTasks.forEach(task => {
                this.validateTask(task, issues, duplicates);
            });

            this.handleIssues(issues, importedTasks, isDryRun);
        },
        checkImportTasks(importedTasks) {

            //eslint-disable-next-line
            debugger;
            const issues = [];
            this.skippedTasks = [];
            this.validTasks = [];

            const duplicates = this.checkForDuplicates(importedTasks);

            importedTasks.forEach(task => {
                this.validateTask(task, issues, duplicates);
            });
            console.log(this.skippedTasks);
            console.log(this.validTasks);
            this.batchImportTasks(this.validTasks, this.skippedTasks, importedTasks);

        },
        validateTask(importedTask, issues, duplicates) {
            if (importedTask.task_no === undefined || importedTask.task_no === null ||importedTask.task_no === '') {
        issues.push({
            message: 'Field "task_no" is empty or undefined.',
            task_no: null, // You can set it to some default value or null based on your requirements
        });
        this.skippedTasks.push(importedTask); // Add the task to skippedTasks
        return; // Exit the method as there's no task_no to validate further
    }
            for (let key in importedTask) {
                let value = importedTask[key];
                if (key === "prod_id") continue
                switch (key) {
                    case "module":
                        if (value === undefined || value === null || value === '') {
                            issues.push({
                                message: `Field "${key}" is empty.`,
                                task_no: importedTask.task_no
                            });
                        }
                        else if (isNaN(parseFloat(value)) || !isFinite(value)) {
                            issues.push({
                                message: `Invalid data type for "${key}". Expected floating-point number, got ${typeof value}.`,
                                task_no: importedTask.task_no
                            });
                        }
                        break;
                    case "task_no":
                    case "chapt_no":
                    case "level":
                    case "ratings":
                        if (value === undefined || value === null || value === '') {
                            issues.push({
                                message: `Field "${key}" is empty.`,
                                task_no: importedTask.task_no
                            });
                        }
                        // Check for invalid data types
                        else if (isNaN(value)) {
                            issues.push({
                                message: `Invalid data type for "${key}". Expected number, got ${typeof value}.`,
                                task_no: importedTask.task_no
                            });
                        }
                        break;
                    case "location":
                    case "AMTOSS":
                    case "activity":
                    case "category":
                    
                        if (value === undefined || value === null || value.trim() === '') {
                            issues.push({
                                message: `Field "${key}" is empty.`,
                                task_no: importedTask.task_no
                            });
                        } else if (typeof value !== "string") {
                            issues.push({
                                message: `Invalid data type for "${key}". Expected string, got ${typeof value}.`,
                                task_no: importedTask.task_no
                            });
                        }
                        break;
                    default:
                        issues.push({
                            message: `Extra field "${key}" detected.`,
                            task_no: importedTask.task_no
                        });
                }
            }
            const validators = {
                //sect_id: val => !this.sectionIds.includes(val) && `Invalid sect_id. No matching section found with ID ${val}.`,
                // prod_id: val => !this.productIds.includes(val) && `Invalid prod_id. No matching product found with ID ${val}.`,
                task_no: (val, task) => {
                    const matchingExistingTask = this.existingTasks.find(
                        existingTask => existingTask.task_no === val,
                    );
                    if (duplicates.includes(task)) return 'Tasks already exists in the product (Duplicate record)';
                    if (matchingExistingTask && !this.areTasksEqual(matchingExistingTask, task)) return `Task with Task No ${val} already exists. Consider adding a new unique number.`;
                },
            };

            Object.entries(importedTask).forEach(([key, value]) => {
                const validationError = validators[key]?.(value, importedTask);
                if (validationError) {
                    issues.push({ message: `${validationError}`, task_no: importedTask.task_no });
                }
            });

            const hasIssues = issues.some(issue => issue.task_no === importedTask.task_no);
            if (hasIssues) {
                this.skippedTasks.push(importedTask);
            } else {
                this.validTasks.push(importedTask);
            }
        },
        handleIssues(issues, importedTasks) {
            if (issues.length) {
                this.$store.dispatch('setIssues', issues);
                this.$store.dispatch('setImportedTasks', importedTasks);
                this.$store.dispatch('setFileName', this.fileName);
                this.$router.push({ name: 'IssuesDisplayB2', params: { productId: this.productId } });
            }
        },

        resetImportState() {
            this.successMessage = null;
            this.processedTasks = [];
        },
    },
}
</script>

<style scoped>
.importButton[disabled] {
    background-color: #ccc;
    cursor: not-allowed;
    opacity: 0.9;
}
</style>
  