<template>
    <div class="modal-overlay" @click="closeModal">
        <div class="products-modal" @click.stop>
            <div class="product-modal-content" @click.stop> <!-- stop the click event from propagating to the overlay -->
                <h2>Add a New Product</h2>
                <table>
                    <tr>
                        <div v-if="suggestedProjectName">
                            <p> Suggested Name: {{ suggestedProjectName }} </p>
                        </div>
                        <input class="productsearch" v-model="productName" type="text" style="width: 100%"
                            placeholder="Product Name" />
                    </tr>
                    <!-- <tr>
                        <div class="dropdown">
                            <button @click="toggleDropdown" style="width:300px; background-color:rgb(166, 190, 166)">Select
                                columns for Tasks</button>
                            <div class="dropdown-menu" :class="{ 'dropdown-menu-active': showDropdown }">
                                        <div class="row-mt-3">
                                    <div class="col-md-6">
                                        <div class="p-2 alert alert-secondary">
                                            <div class="list">
                                        <h3 style="font-size: 16px;"><b>Available Columns</b></h3>
                                        <draggable class="list-group kanban-column" v-model="allColumns" group="columns" :options="{ key: 'name' }">
                                        <template #item="{ element }">
                                            <div class="element">{{ element.name }}</div>
                                        </template>
                                    </draggable>
                                    </div>
                                    </div>
                                    </div>
                                </div>
                                    <div class="row-mt-3">
                                    <div class="col-md-6">
                                        <div class="p-2 alert alert-primary">
                                            <div class="list">
                                    <h3 style="font-size: 16px;"><b>Selected Columns</b></h3>
                                    <draggable class="list-group kanban-column" v-model="selectedColumns" group="columns" :options="{ key: 'name' }">
                                        <template #item="{ element }">
                                            <div class="element">{{ element.name }}</div>
                                        </template>
                                    </draggable>
                                </div>
                                    </div>
                                    </div>
                                </div>
                            </div> 
                        </div> 
                    </tr> -->
                    <tr>
                        <button style="width: 40%;" class="saveButton" @click="saveButton">Save</button>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
//import draggable from 'vuedraggable'
 
export default {
    data() {
        return {
            allColumns: [{name: "Task No."}, {name: "Module"}, {name: "Chapter No."}, {name: "Location"}, {name: "Area"},{name: "AMTOSS"}, {name: "Activity"}, {name: "Category"}, {name: "Level"}, {name: "Manob"}, {name: "Ratings"},],
            selectedColumns: [],
            aircrafts: [],
            specifics: [],
            selectedAircraft: 'A380',
            selectedSpecific: 'RR Trent-700',
            productName: '',
            showDropdown: false,
        };
    },
    // components: {
    //     draggable,
    // },

    mounted() {
        this.fetchData();
        window.addEventListener('show-alert', this.handleShowAlert);
    },
    beforeUnmount() {
        window.removeEventListener('show-alert', this.handleShowAlert);
    },


    methods: {
        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },
        async fetchData() {
            try {
                //eslint-disable-next-line
                debugger;
                const token = localStorage.getItem('token');
                const headers = { Authorization: `Bearer ${token}` };
                let response = await axios.get('/api/aircraft', { headers });
                this.aircrafts = response.data;
                console.log(response);

                response = await axios.get('/api/specifics', { headers });
                this.specifics = response.data;
                console.log(this.specifics);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        },
        closeModal() {
            location.reload();
            this.$emit('close');
        },
        async saveButton() {
            //eslint-disable-next-line
            debugger;
            // Check if values are set before making the API call
            if (this.selectedAircraft && this.productName) {
                try {
                    const token = localStorage.getItem('token');
                    const headers = { Authorization: `Bearer ${token}` };
                    // Set spec_id to null if selectedSpecific is an empty string
                    //const spec_id = this.selectedSpecific === '' ? null : this.selectedSpecific;

                    const data = {
                        ac_id: 1,
                        spec_id: 1,
                        name: this.productName
                    };
                    console.log("data", data);
                    const response = await axios.post('/api/products', data, { headers });

                    if (response.status === 201) {
                        window.alert('Product added Successfully');
                        this.closeModal();
                    } else {
                        window.dispatchEvent(new CustomEvent('show-alert', { detail: "Product addition failed" }));
                    }
                } catch (error) {
                    console.error("There was an error with the fetch operation:", error);
                }
            } else {
                console.error("Please select an aircraft, and enter a product name.");
            }
        }

    }
};

</script>

<style scoped>
.kanban-column {
    min-height: 300px;
}
.lists-container {
    display: flex;
    justify-content: space-between;
}

.list {
    width: 250px;
    padding: 8px;
}

.lists-container ul {
    list-style: none;
    padding: 0;
}

.element {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.7);
    border: 1px solid grey;
    padding: 3px;
    border-radius: 3px;
    padding-left: 8px;
}

.assign-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* Updated to stack buttons vertically */
}

.assign-button button {
    font-size: 20px;
    background-color: #0a407a;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 5px;
    /* Optional: Add margin between buttons */
    margin-right: 20px;
}

.dropdown {

    display: inline-block;
    margin-bottom: 10px;
    margin-right: 200px;
}

.dropdown-menu {
    display: none;
    background-color: #f1f1f1;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 1;
    position: absolute;
}

/* Display the dropdown menu when showDropdown is true */
.dropdown-menu-active {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Display 3 columns */
    background-color: #f1f1f1;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 1;
    position: absolute;
    width: 600px;
}

table {
    border-collapse: collapse;
}

th,
td {
    border: 1px solid black;
    padding: 8px;
}

.modal-overlay {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.products-modal {
    background-color: #fff;
    max-height: 90vh;
    top: 100px;
    max-width: 90%;
    min-width: 500px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    position: fixed;
    display: block;
    z-index: 1001;
}

.aircraftdropdown,
.productsearch {
    height: 40px;
    /* adjust this value as needed --*/
    margin-bottom: 10px;
    width: 80%;
    margin-right: 40px;
}


.product-modal-content {
    border: white;
}

.product-modal-content h2 {
    font-size: 24px;
}

table {
    width: 100%;
}

.saveButton {
    background-color: #0a407a;
    color: #ffffff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: auto;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    align-self: center;
}
</style>